import { render, staticRenderFns } from "./xsmb.vue?vue&type=template&id=25851c0d&scoped=true&"
import script from "./xsmb.vue?vue&type=script&lang=js&"
export * from "./xsmb.vue?vue&type=script&lang=js&"
import style0 from "./xsmb.vue?vue&type=style&index=0&id=25851c0d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25851c0d",
  null
  
)

export default component.exports